<template>
  <div>
    <BaseButton
      variant="salmon"
      class="justify-center w-full"
      @click="showDowngradeModal = true"
    >
      Downgrade to {{ plan.name }}
    </BaseButton>
    <Modal
      :open="showDowngradeModal"
      :can-close="!upgradeInProgress"
      @close="showDowngradeModal = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Downgrade subscription
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showDowngradeModal = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="!upgradeInProgress"
        class="p-6"
      >
        <p class="mb-3">
          Currently it’s not possible to downgrade automatically. Please contact support and we’ll help you out.
        </p>
        <!-- <p class="inline-flex my-3 space-x-2">
          <BaseButton @click="upgradeSubscription()">
            Upgrade Now
          </BaseButton>
          <BaseButton
            variant="salmon"
            @click="showDowngradeModal = false"
          >
            No, Keep Existing Plan
          </BaseButton>
        </p> -->
        <p
          v-if="upgradeErrorMessage"
          class="my-3"
        >
          {{ upgradeErrorMessage }}
        </p>
      </div>
      <div
        v-else-if="upgradeInProgress"
        class="p-6 text-center"
      >
        <Loader />
        <p class="my-3 text-sm">
          Updating your subscription
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'
import Icon from '@components/Icons/Icon'

export default {
  components: {
    Loader,
    Modal,
    Plus,
    Icon
  },

  props: {
    plan: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showDowngradeModal: false,
      upgradeInProgress: false,
      upgradeErrorMessage: null
    }
  },

  methods: {
    //
  }
}
</script>
