<template>
  <BaseContainer>
    <PAYGChangePlanSettings />
  </BaseContainer>
</template>

<script>
import PAYGChangePlanSettings from '@components/Settings/PAYGChangePlanSettings'

export default {
  page: {
    title: 'Change Plan'
  },

  components: {
    PAYGChangePlanSettings
  }
}
</script>
