<template>
  <div>
    <SettingsNavigation />

    <BaseWrapper>
      <PAYGChangePlansCard />
    </BaseWrapper>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import PAYGChangePlansCard from '@components/Plans/PAYGChangePlansCard'

export default {
  components: {
    SettingsNavigation,
    PAYGChangePlansCard
  }
}
</script>
